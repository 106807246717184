.chargeback-data-table-container {
  // margin-top: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  nav.MuiPagination-root {
    margin-top: 12px;
    margin-bottom: 8px;
    // position: absolute;
    right: 38px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;

    .MuiPagination-ul {
      li button.MuiPaginationItem-page {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
        background: #ffffff;
        color: #43425d;
      }

      li:first-child button.MuiPaginationItem-page,
      li:last-child button.MuiPaginationItem-page {
        background: transparent;
        color: #0092ff;
      }

      li button.MuiPaginationItem-page.Mui-selected {
        border: 1px solid #0092ff;
        color: #0092ff;
      }
    }
  }

  .chargeback-table-header {
    margin: 2px 42px;

    .chargeback-data-table-filter {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem 0.8rem;

      .search-filter-wrapper {
        width: 24%;
        max-height: 38px;
        background-color: #fff !important;
        border: 1px solid #cecfd0;
        font-weight: 200;
        padding: 0.5rem 1.25rem;
        border-radius: 100px;
        display: flex;
        position: relative;
        flex-flow: column;

        .searchbar-input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          color: #000;
          font-weight: 300;
        }
      }
      .search-container {
        display: flex;
        flex-flow: column;
        font-weight: 200;
        gap: 5px;

        .search-scope {
          font-size: 8px;
          font-weight: 500;
          color: #000000;
          span {
            color: #0092ff;
          }
        }
        .search-filter-wrapper {
          width: 240px;
          background-color: #fff !important;
          border: 1px solid #cecfd0;
          font-weight: 200;
          padding: 0.5rem 8px;
          border-radius: 18px;
          display: flex;
          position: relative;
          flex-flow: column;

          &:hover {
            border-color: #0092ff;
          }

          &.show-border-cv {
            border: 1px solid #0092ff;
            border-top-left-radius: 22px;
            border-top-right-radius: 22px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          .advanced-search-container {
            display: flex;
            width: 100%;
          }
          .cursor-pointer {
            cursor: pointer;
          }
          .advanced-search-option {
            position: absolute;
            z-index: 1;
            background: #ffffff;
            left: -1px;
            top: 26px;
            background-color: #fff;
            box-shadow: 0 2px 10pxrgba (0, 0, 0, 0.15);
            display: none;
            right: 0;
            width: 240px;
            &.active-cv {
              display: block;
              border: 1px solid #0092ff;
              border-top: 0;
              border-bottom-left-radius: 22px;
              border-bottom-right-radius: 22px;
              margin-top: 4px;
              z-index: 100;
              overflow: hidden;
            }
            .options {
              background-color: transparent;
              margin-bottom: 5px;
              .option-cv {
                align-items: center;
                color: #333;
                display: flex;
                font: normal normal 400 13px/24px Montserrat;
                height: 40px;
                justify-content: flex-start;
                width: 100%;
                padding: 0.6rem 8px;
                cursor: pointer;
                &:hover {
                  background-color: #f5f5f5;
                }
              }
            }
          }
          img {
            cursor: pointer;
          }

          input {
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            color: #000;
            &::-webkit-search-cancel-button {
              cursor: pointer;
              margin-left: 4px;
              background-color: #000;
              color: #000;
            }
            font-size: 14px;
          }
        }
      }

      .company-name-selector {
        width: 240px;
      }

      .type-selector {
        width: 240px;
      }

      .date-range-picker {
        width: 240px;
        max-height: 38px;

        .rs-input-group.rs-input-group-inside {
          border-radius: 18px;
          border: 1px solid #cecfd0;
          &:hover {
            border-color: #0092ff;
          }
          .rs-input {
            border-radius: 18px;
            cursor: pointer;
            font-size: 13px;
            line-height: 1.7;
          }
          .rs-input::placeholder {
            color: hsl(0, 0%, 50%);
          }
          .rs-input-group-addon {
            cursor: pointer;
          }
        }
      }

      .date-picker {
        border-color: #cedfd0;
        width: 240px;
        max-height: 38px;

        .rs-input-group.rs-input-group-inside {
          border-radius: 18px;
          border: 1px solid #cecfd0;
          &:hover {
            border-color: #0092ff;
          }

          .rs-input {
            border-radius: 18px;
            cursor: pointer;
            font-size: 13px;
            line-height: 1.7;
          }
          .rs-input::placeholder {
            color: hsl(0, 0%, 50%);
          }
          .rs-input-group-addon {
            cursor: pointer;
          }
        }
      }

      .column-wrapper {
        max-height: 38px;

        .column-button {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: center;
          background: none !important;
          border: 1px solid #cecfd0;
          border-radius: 60px;
          color: hsl(0, 0%, 50%);
          font-size: 13px;
          line-height: 1.6;
          padding: 7px 14px;
          width: 240px;

          &:hover {
            border-color: #0092ff;
          }

          &:focus {
            box-shadow: 0 0 0 1px #2684ff;
          }

          .downward-icon {
            margin-left: auto;
            vertical-align: middle;
          }
        }

        .headers-list {
          padding: 5px;
          width: 100%;

          li {
            border-bottom: 1px solid #e2e2e2;
            display: flex;
            gap: 5px;
            justify-content: space-between;
            padding: 9px 11px 9px 10px;

            label {
              align-items: center;
              cursor: pointer;
              display: flex;
              font-size: 12px;
              justify-content: space-between;
              text-transform: capitalize;
              width: 100%;
            }
          }
        }
        .hidden {
          display: none !important;
        }

        .block {
          margin: 0.5rem;
          background-color: #fff !important;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.102);
          display: flex;
          flex-flow: row-reverse;
          padding: 5px;
          position: absolute;
          width: 230px;
          z-index: 1;
          max-height: calc(100vh - 340px);
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #0092ff;
            border-radius: 10px;
          }
        }
      }

      .refresh-button-container {
        background-color: #e5f4ff;
        padding: 8px 12px;
        border-radius: 1rem;
        margin-left: auto;
        text-align: center;
        vertical-align: center;
        cursor: pointer;
        max-height: 38px;

        .refresh-table {
          height: 20px;
          font-weight: 800;
        }
      }

      input::placeholder {
        font-size: 13px;
      }
    }
  }
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content {
  text-decoration: none;
}

.chargeback-table-container {
  margin-top: 10px;
  height: calc(100vh - 380px);
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0092ff;
    border-radius: 10px;
  }

  .no-data-message {
    text-align: center;
    padding: 1rem;
    width: 100%;
    position: absolute;
    top: 50%;

    p {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.rwd-chargeback-table {
  font-size: 13px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0;
  }

  .last-child-header {
    text-align: left;
    margin-right: 8px;
  }
}

.rwd-chargeback-table th {
  border-top: none;
  background: #f5f6fa;
  text-align: center;
  color: #43425d;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  height: 4rem;
}

.header-small {
  width: 10vw;
}

.header-medium {
  width: 12vw;
}

.header-large {
  width: 14vw;
}

.header-text {
  vertical-align: middle;
}

.sort-icon {
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}

.rwd-chargeback-table tr {
  background-color: #fff;
  color: #43425d;
  padding: 4px 0;
  border-color: #bfbfbf;
  user-select: none;
  cursor: default;
}

tbody tr {
  border-bottom: 3px solid #f5f6fa !important;
  text-align: center;
}

.rwd-chargeback-table tr th {
  text-align: center;
  user-select: none !important;
  height: 40px;
}

.rwd-chargeback-table tr:nth-child(odd) {
  background-color: #fff;
}

.rwd-chargeback-table td:first-child {
  margin-top: 0.5em;
}

.rwd-chargeback-table td:last-child {
  margin-bottom: 0.5em;
}

.rwd-chargeback-table tr:hover {
  background-color: #f5f6fa;
}

.rwd-chargeback-table td:before {
  display: none;
}

.rwd-chargeback-table th:first-child,
.rwd-chargeback-table td:first-child {
  padding-left: 0;
}

.rwd-chargeback-table th:last-child,
.rwd-chargeback-table td:last-child {
  padding-right: 0;
}

.rwd-chargeback-table th,
td {
  text-align: center;
  display: table-cell;
  padding: 1.2em 1em !important;
  user-select: all !important;
}

.rwd-chargeback-table .actions-header {
  // border: 1px solid #0092ff;
  padding: 0.2em 1em !important;
  height: 24px;
  padding-bottom: 0.8em !important;
}
