  .edit-card-list-container {
    .available-modules-heading {
      color: #43425d;
      font-size: 1rem;
      font-weight: normal;
    }
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .edit-credit-card {
      border: 1px solid #cbcbcb;
      border-radius: 10px;
      padding: 1rem 1.5rem;
      .card-module-heading {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 30px;
        color: #43425d;
        margin-right: auto;
        &::first-letter {
          text-transform: uppercase;
        }
      }

      .card-divider {
        border-top: 1px solid #cbcbcb;
        margin: 1rem 0;
      }

      .credit-details-container {
        display: flex;
        padding: 0.6rem 0;
        flex-wrap: wrap;
        gap: 1rem;
        .current-credits {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 1rem;
          white-space: nowrap;
          .credits-label {
            font-weight: 500;
            font-size: 0.8rem;
            color: #43425d;
          }
          .credits-value {
            font-weight: normal;
            font-size: 0.8rem;
            color: #000000;
          }
        }
        .edit-credits-container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          width: 40rem;
          z-index: 0;
        }

        .edit-credits-input {
          width: 80%;
          display: flex;
          align-items: center;
          gap: 1rem;
          .credits-input-label {
            // kept for future use
            display: none;
            max-width: 6rem;
            min-width: 6rem;
            white-space: nowrap;
            font-weight: 500;
            font-size: 0.8rem;
            color: #43425d;
          }
          .edit-input-container {
            flex: 1;
            border-radius: 10px;
            border: 1px solid #cbcbcb;
            .credits-field-input {
              background-color: transparent;
              outline: none;
              border: 0;
              font-size: 14px;
              padding: 8px 18px;
              width: 100%;
              font: normal normal 400 13px/24px Montserrat;
              user-select: none;
              color: #000000;
              -moz-appearance: textfield;

              &::placeholder {
                font-weight: normal;
                color: #9f9f9f;
              }

              &:focus {
                outline: none;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
          .error-border {
            border: 1px solid red;
          }
        }
      }
    }
  }

// callback loader
.credits-loader-container {
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 3rem;
  }
}

// no modules found message
.no-modules-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0092ff;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 2rem;
  p{
    font-size: .8rem;
    font-weight: 500;
  }
}
