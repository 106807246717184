.ui-component {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ui-component-heading-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 1rem;
    img {
      cursor: pointer;
      width: 1.2rem;
    }
    h1.heading {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      color: #43425d;
      display: flex;
      align-items: center;
      .highlighted-heading-text {
        color: #0092ff;
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }
}

.ui-component-page {
  height: 100%;
  overflow-y: auto;

  .ui-component-content {
    padding: 18px 30px 0px 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    height: max-content;

    .ui-component-form {
      padding: 24px 18px;
    }
  }
}

.ui-component-page {
  .table-component-content {
    padding: 0;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    h1.heading {
      padding: 18px 0px 0px 30px;
    }
  }
}
