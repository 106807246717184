.kyb-id__block {
  font-size: 0.8rem;
  padding: 0.8rem;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #cbcbcb;
  gap: 2rem;

  .kyd-id__values {
    color: #43425d;

    span {
      color: #000;
      font-weight: 600;
    }
  }
}
