.available-modules-container {
  .available-modules-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      img {
        width: 1.2rem;
      }

      h1.heading {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 30px;
        color: #43425d;
        display: flex;
        align-items: center;

        .company-name-highlight {
          font-size: 1.2rem;
          font-weight: 600;
          padding-left: 0.5rem;
          color: #0092ff;
        }
      }
    }

    // BTN CSS
    .update-btn-container{
      display: flex;
      gap: 10px;
    }
    
    .manage-credit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      border: none;
      outline: none;
      opacity: 0.5;
      padding: 10px 28px;
      border-radius: 18px;
      background: #0092ff;
      color: #ffffff;
      font-size: 13px;
      font-weight: 500;

      span {
        font-size: 1.4rem;
      }

      &:hover {
        cursor: not-allowed;
      }
    }

    .active-credit-btn {
      opacity: 1;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .subscribed-module-list-container {
    // Divider
    .credits-management-divider {
      border-top: 1px solid #cbcbcb;
      margin: 2rem 0;
    }
  }
}

// No company selected
.no-company-selected-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0092ff;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 2rem;
  p{
    font-size: .8rem;
    font-weight: 500;
  }
}