.checkbox-form-wrapper {
  form {
    display: flex;
    margin: 1.4rem 0;
  }

  .formik-checkbox-field {
    display: flex;
    align-items: center;
    justify-content: center;

    .formik-checkbox-field__label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-size: 0.925rem;
      font-weight: 400;
      margin-right: 1.8rem;
    }
  }

  .formik-btn-submit {
    margin-left: auto;
    border: 1px solid #f5f5f5;
    border-radius: 50px;
    padding: 12px 24px;
    background-color: #0092ff;
    color: #ffffff;
    font-size: 14px;
  }

  .formik-btn-submit.active {
    color: #ffffff;
    background-color: #0092ff; /* Change background color for active state */
  }

  .formik-btn-submit.disabled {
    opacity: 0.5; /* Reduce opacity when button is disabled */
    cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
  }
}
