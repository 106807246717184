@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	font-family: 'Montserrat', sans-serif;
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
ul,
li {
	margin: 0;
	padding: 0;
}

button {
	cursor: pointer;
	outline: none;
	border: none;
	padding: 0;
	margin: 0;
}

input {
	outline: none;
	border: none;
}

select,
textarea {
	outline: none;
}

a {
	text-decoration: none !important;
}

ul {
	list-style: none;
}

div {
	outline: none;
}

div#root {
	display: flex;
	height: 100%;
	width: 100%;
}

input.type1 {
	font-size: 14px;
	color: #43425D;
	font-weight: 400;
	padding: 0 3px 10px 0;
	border-bottom: 1px solid rgba(67, 66, 93, 0.3);
}

select.type1 {
	border: none;
	font-size: 14px;
	color: #43425D;
	font-weight: 400;
	padding: 0 3px 10px 0;
	border-bottom: 1px solid rgba(67, 66, 93, 0.3);
}

.select {
	position: relative;

	select:invalid {
		color: gray;
	}
}

.select-text {
	border: none;
	position: relative;
	font-family: inherit;
	background-color: transparent;
	width: 120px;
	padding: 7px;
	// padding-bottom: 4px;
	font-size: 12px;
	border-bottom: 1px solid #a7a9ab;
}

.select-text:focus {
	outline: none;
	border-bottom: 2px solid #0092ff;
}

.select .select-text {
	appearance: none;
	-webkit-appearance: none;
	color: rgb(0, 0, 0);
	background-color: #FFFFFF;
	padding: 10px;
	min-width: 200px;
	border-radius: 5px;
	outline: 0;
	border-bottom: 0;
}

.select:after {
	position: absolute;
	top: 12px;
	right: 10px;
	width: 0;
	height: 0;
	padding: 0;
	content: "";
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid rgba(0, 0, 0, 0.5);
	pointer-events: none;
}

.select-label {
	color: rgba(0, 0, 0, 0.534);
	background: white;
	font-size: 12px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	padding: 0 5px;
	left: 10px;
	top: 5px;
	transition: 0.4s ease all;
}

.select-text:focus~.select-label,
.select-text:valid~.select-label {
	color: #0092ff;
	top: -8px;
	transition: 0.4s ease all;
	font-size: 12px;
}

.select-text:valid:focus~.select-label {
	color: #0092ff;
}

@mixin label-inactive() {
	font-size: 12px;
	transform: translateY(0);
	color: #808495;
}

@mixin label-active() {
	color: #0092ff;
	font-size: 0.75rem;
	transform: translateY(-14px);
}

@mixin label-inactive-valid() {
	font-size: 12px;
	transform: translateY(0);
	color: #808495;
}

.form-field {
	// border: 1px solid green;
	width: 250px;
	display: block;
	margin-bottom: 16px;
	margin-left: auto;
}

.form-field__label {
	@include label-active();
	display: block;
	font-weight: normal;
	left: 0;
	margin: 0;
	padding: 20px 12px 0;
	position: absolute;
	top: 3px;
	transition: all 0.4s;
	width: 100%;
}

.form-field__control {
	border-radius: 8px 8px 0 0;
	overflow: hidden;
	position: relative;
	width: 250px;
}

.form-field__bar {
	border-bottom: 2px solid #0092ff;
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	transform: scaleX(0);
	transition: all 0.4s;
	width: 3%;
}

.form-field__input {
	appearance: none;
	background: transparent;
	border: 0;
	border-bottom: 1px solid #999;
	color: #333;
	display: block;
	font-size: 12px;
	margin-top: 20px;
	outline: 0;
	padding: 5px 12px;
	width: 100%;

	&:-ms-input-placeholder {
		~.form-field__label {
			@include label-inactive();
		}
	}

	&:placeholder-shown {
		~.form-field__label {
			@include label-inactive();
		}
	}

	&:focus,
	:valid {
		~.form-field__label {
			color: #0092ff;
			@include label-active();
		}

		~.form-field__bar {
			border-bottom: 2px solid #0092ff;
			transform: scaleX(150);
		}
	}
}

.checkbox-container {
	flex-basis: 238px;

	input[type="checkbox"]+label {
		position: relative;
		padding-left: 15px;
		color: rgb(0, 0, 0);
		cursor: pointer;
		margin-bottom: 0;
	}
}

.required-field {
	color: red;
	margin-left: 3px;
}