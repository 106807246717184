.loader {
  height: 15px;
  width: 15px;
  margin-left: 10px;
}

.loader-div {
  min-width: 80px;
  padding: 10rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
