.ui-form-input-section {
  position: relative;
  width: 100%;

  .date-range-input {
    position: relative;
    cursor: pointer;
    width: 70% !important;

    .date-range-display {
      width: 100%;
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 14px;
      color: #43425d;

      &::placeholder {
        color: #999;
      }
    }

    .calendar-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      color: #666;
    }
  }

  .calendar-dropdown {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 300px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 16px;

    .calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      position: relative;

      button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 4px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;

        &:hover {
          background: #f5f5f5;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .calendar-selectors {
        position: relative;

        .year-month-selector {
          cursor: pointer;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;

          &:hover {
            background: #f5f5f5;
          }
        }

        .year-selector {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          background: white;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          padding: 8px;
          max-height: 200px;
          overflow-y: auto;
          z-index: 1;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 4px;

          button {
            padding: 4px 8px;
            border: none;
            background: none;
            cursor: pointer;
            border-radius: 4px;
            font-size: 14px;

            &:hover {
              background: #f5f5f5;
            }

            &.selected {
              background: #1890ff;
              color: white;
            }
          }

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 3px;
          }
        }
      }
    }

    .calendar-days {
      .weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;
        margin-bottom: 8px;

        span {
          font-size: 12px;
          color: #666;
        }
      }

      .days-grid {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 2px;

        .empty-cell {
          aspect-ratio: 1;
        }

        .day-cell {
          aspect-ratio: 1;
          border: none;
          background: none;
          cursor: pointer;
          border-radius: 4px;

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          &:hover {
            background: #f5f5f5;
          }

          &.selected {
            background: #e6f4ff;
          }

          &.start {
            background: #1890ff;
            color: white;
          }

          &.end {
            background: #1890ff;
            color: white;
          }
        }
      }
    }
  }
}

.ui-form-content-input {
  .ui-label {
    .required {
      color: #de6767;
      margin-left: 2px;
    }
  }
}
