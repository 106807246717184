#portal .container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}

.container .reports-modal {
  z-index: 2;
  width: max-content;
  height: max-content;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  position: relative;
  animation: animate 0.3s;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 10px;
}

.success-status-icon {
  background: #f1fcf6;
  height: 2rem;
  margin-right: 5px;
  border-radius: 8px;
}

.close-modal-btn {
  position: absolute;
  top: -28px;
  right: -28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f9ff 0% 0% no-repeat padding-box;
  color: white;
  padding: 4px;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 50%;

  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    color: #0092ff;
  }
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

// modal heading styles
.reports-modal-heading {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 22px;
    font-weight: 600;
    color: #43425d;
  }
}

.reports-modal-subtitle,
.success-message,
p {
  width: 100%;
  text-align: center;
  font: normal normal normal 13px/16px Montserrat;
  font-weight: 500;
  letter-spacing: 0px;
  color: #808495;
  margin-bottom: 2rem;
}

.reports-response-list-item {
  display: flex;
  align-items: center;
  justify-content: center;

  .field {
    color: #43425d;
    font-size: .8rem;
    text-align: left;
    font-weight: 500;
    display: inline-block;
    text-wrap: nowrap;
    margin-right: 8px;
  }

  .value {
    color: #43425d;
    font-size: .8rem;
    text-align: right;
    text-wrap: wrap;
    word-break: break;
    &.status-active {
      font-weight: 600;
      color: rgba(137, 205, 36, 0.769);
    }
    &.status-inactive {
      color: #f44336;
      font-weight: 600;
    }
  }

  .status-icon {
    background: #fdf3f3;
    height: 14px;
    margin-left: 16px;
    margin-top: 7px;
    border-radius: 8px;
  }
}

.download-container {
  margin-top: 1rem;
}
