// Dropdown CSS
.edit-credit-type {
  // width: calc(50vw - 6rem - 80px);
  width: calc(50%);
  user-select: none;
  position: relative;
  display: block;
  .dropdown-divider {
    background-color: #fff;
    span {
      display: block;
      height: 1px;
      background-color: transparent;
      width: 86%;
      margin: 0 auto;
    }
  }
  .text-dark {
    color: #333;
  }

  .text-light {
    color: #9f9f9f;
  }

  .edit-credit-label {
    font-weight: 500;
    font-size: 0.8rem;
    color: #43425d;
  }

  .dropdown-btn {
    padding: 8px 18px;
    border-radius: 10px;
    background: #fff;
    font-size: 12px;
    font-weight: 400;
    font: normal normal 400 12px/24px Montserrat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cbcbcb;
    cursor: pointer;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #43425d;
    }
  }
  .dropdown-btn.border-radius__invert {
    border: 1px solid #0092ff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }

  .credit-type-content {
    position: absolute;
    z-index: 99;
    top: 40px;
    left: 0;
    padding: 0 10px;
    background: #fff;
    border: 1px solid #0092ff;
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 2px 6px #0000000a;
    font-weight: 400;
    color: #333;
    width: 100%;
    max-height: 150px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 8px;
      margin-bottom: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      // background: #000;
      background: #cbcbcb;
      border-radius: 8px;
    }
  }

  .credit-type-content {
    .dropdown-item {
      font-size: 12px;
      padding: 16px 10px;
      cursor: pointer;
      transition: all 0.2s;
    }
    .dropdown-item:hover {
      background: #f4f4f4;
    }
  }
}

// dropdown arrow
.dropdown-arrow {
  width: 18px;
}
.dropdown-arrow__rotate {
  transition: transform 0.5s ease-in-out;
  transform: rotate(180deg);
}
