.payment-channel-form {
  .ui-toggle-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 3rem;
    justify-content: space-between;
    .ui-form-toggle-field {
      width: 100%;
    }
  }
  hr {
    width: 100%;
    margin-top: 40px;
    background-color: #ccc;
  }
}
