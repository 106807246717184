.pa-onboard-summary-details {
  .pa-summary-action-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .pa-summary-action-label {
      font-weight: 400;
      font-size: 0.825rem;
      font-weight: 600;
    }

    .pa-summary-button-container {
      .formik-btn-submit {
        margin-left: auto;
        border: 1px solid #f5f5f5;
        border-radius: 50px;
        padding: 12px 24px;
        background-color: #0092ff;
        color: #ffffff;
        font-size: 14px;
      }

      .formik-btn-submit.active {
        color: #ffffff;
        background-color: #0092ff; /* Change background color for active state */
      }

      .formik-btn-submit.disabled {
        opacity: 0.5; /* Reduce opacity when button is disabled */
        cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
      }
    }
  }

  .dmo-table {
    margin-top: 1rem;
    overflow: auto;
    width: 100%;
    table {
      border: 1px solid #dededf;
      height: 100%;
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 1px;
      text-align: left;
    }
    caption {
      caption-side: top;
      text-align: left;
    }
    th {
      border: 1px solid #dededf;
      background-color: #eceff1;
      color: #000000;
      padding: 8px;
    }

    td {
      border: 1px solid #dededf;
      background-color: #ffffff;
      color: #000000;
      padding: 5px;
      font-size: 0.8rem;
    }
    td.reference-id-field {
      font-size: 0.8rem;
    }

    .dmo-button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 2rem;
      .formik-btn-submit {
        // margin-left: auto;
        border: 1px solid #f5f5f5;
        border-radius: 50px;
        padding: 12px 24px;
        background-color: #0092ff;
        color: #ffffff;
        font-size: 14px;
      }

      .formik-btn-submit.active {
        color: #ffffff;
        background-color: #0092ff; /* Change background color for active state */
      }

      .formik-btn-submit.disabled {
        opacity: 0.5; /* Reduce opacity when button is disabled */
        cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
      }
    }
  }

  .pa-done-button-container {
    display: flex;
    justify-content: end;
    margin-top: 8rem;
    .formik-btn-submit {
      margin-left: auto;
      border: 1px solid #f5f5f5;
      border-radius: 50px;
      padding: 12px 24px;
      background-color: #0092ff;
      color: #ffffff;
      font-size: 14px;
    }

    .formik-btn-submit.active {
      color: #ffffff;
      background-color: #0092ff; /* Change background color for active state */
    }

    .formik-btn-submit.disabled {
      opacity: 0.5; /* Reduce opacity when button is disabled */
      cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
    }
  }
}
