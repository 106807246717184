.all-callback-table-container {
  margin-top: 20px;
  overflow: auto;
  nav.MuiPagination-root {
    margin-top: 10px;
    position: absolute;
    right: 38px;

    .MuiPagination-ul {
      li button.MuiPaginationItem-page {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
        background: #ffffff;
        color: #43425d;
      }

      li:first-child button.MuiPaginationItem-page,
      li:last-child button.MuiPaginationItem-page {
        background: transparent;
        color: #0092ff;
      }

      li button.MuiPaginationItem-page.Mui-selected {
        border: 1px solid #0092ff;
        color: #0092ff;
      }
    }
  }

  .callback-table-edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    font-weight: 600;
    color: #0092ff;
    background: none;
    padding: 8px;
    img {
      transform: rotate(180deg);
      width: 1rem;
    }
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
  }

  .csv-download {
    border: none;
    outline: none;
    padding: 10px 30px;
    border-radius: 100px;
    background: #e5f4ff;
    color: #0092ff;
    opacity: 1;
    font-weight: 600;
    font-size: 13px;
  }

  .data-table-global-filter {
    border: 1px solid #cecfd0;
    width: 300px;
    padding: 6px 14px;
    border-radius: 18px;
    input {
      width: 100%;
      font-size: 13px;
      padding: 4px 6px;
    }
    input::placeholder {
      font-size: 13px;
    }
  }
}

.table-Container {
  margin-top: 20px;
  height: calc(100vh - 366px);
  overflow: auto;
}

.rwd-table {
  font-size: 13px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .last-child-header {
    text-align: left;
    margin-right: 8px;
  }
}

// @ to handle last child header styles
// .rwd-table-header th:last-child {
//   text-align: left;
//   margin-right: 8px;
// }

.no-data__message {
  text-align: center;
  padding: 2rem;
  width: 100%;
  p {
    font-size: 16px;
    font-weight: 400;
  }
}

.rwd-table th {
  border-top: none;
  background: #f5f6fa;
  text-align: center;
  color: #43425d;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  // border: 1px solid #0092ff;
  height: 4rem;
}

.header-small {
  width: 10vw;
}

.header-medium {
  width: 12vw;
}

.header-large {
  width: 14vw;
}

.header-wrap-text {
  white-space: pre-line; // Set white-space to 'pre-line' for text wrapping
  border: 1px solid red;
}

.header-text {
  vertical-align: middle;
}

.sort-icon {
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}

.rwd-table tr {
  background-color: #fff;
  color: #43425d;
  padding: 4px 0;
  border-color: #bfbfbf;
  user-select: none;
  cursor: default;
}

tbody tr {
  border-bottom: 3px solid #f5f6fa !important;
  text-align: center;
}

.rwd-table tr th {
  text-align: center;
  user-select: none !important;
  height: 40px;
}

.rwd-table tr:nth-child(odd) {
  background-color: #fff;
}

.rwd-table td:first-child {
  margin-top: 0.5em;
}

.rwd-table td:last-child {
  margin-bottom: 0.5em;
}

.rwd-table tr:hover {
  background-color: #f5f6fa;
}
.rwd-table td:before {
  display: none;
}

.rwd-table th:first-child,
.rwd-table td:first-child {
  padding-left: 0;
}
.rwd-table th:last-child,
.rwd-table td:last-child {
  padding-right: 0;
}
.rwd-table th,
td {
  text-align: center;
  display: table-cell;
  padding: 1.2em 1em !important;
  user-select: all !important;
}

.rwd-table .actions-header {
  // border: 1px solid #0092ff;
  padding: 0.2em 1em !important;
  height: 24px;
  padding-bottom: 0.8em !important;
}

.table-search-header {
  .search-container-wrapper {
    .search-form-container {
      width: max-content;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 1rem;
      margin-right: 30px;

      .data-table-all-data-filter {
        border: 1px solid #cecfd0;
        width: 300px;
        padding: 6px 14px;
        border-radius: 18px;
        display: flex;

        input {
          width: 100%;
          font-size: 13px;
          padding: 4px 6px;
        }

        input::placeholder {
          font-size: 14px;
        }

        .clear-search-field {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.4rem;
          cursor: pointer;
        }
      }

      button.search-btn {
        padding: 0.6rem 1rem;
        border-radius: 1rem;
        font-weight: 500;
      }
    }
  }
}

.visible-false {
  visibility: hidden;
  display: none;
}
