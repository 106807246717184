.form-message-block {
  font-size: 14px;
  font-weight: 500;
  color: #0092ff;
  background-color: #e4f2fd;
  border: 1px solid #0092ff;
  margin-top: 2rem;
  padding: 1rem 1.2rem;
  border-radius: 10px;
}


.onboarding-form-message-block {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  color: #0092ff;
  background-color: #e4f2fd;
  border: 1px solid #0092ff;
  border-radius: 10px;
  height: 10rem;
}
