// FILTER SEARCH FIELD
.callback-search-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  margin-bottom: 2rem;
  width: 37vw;

  .callback-search-input-label {
    font-size: 1rem;
    font-weight: 500;
    color: #43425d;
    user-select: none;
    &::after {
      content: " *";
      color: red;
    }
  }

  .searchInputs {
    display: flex;
    border-radius: 10px;
    border: 1px solid #cbcbcb;

    .callback-search-input {
      background-color: transparent;
      outline: none;
      border: 0;
      border-radius: 2px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      font-size: 14px;
      padding: 20px 18px;
      height: 30px;
      width: calc(37vw - 45px);
      font: normal normal 400 14px/24px Montserrat;
      user-select: none;
      color: #000000;

      &::placeholder {
        font-weight: normal;
        color: #9f9f9f;
      }

      &:focus {
        outline: none;
      }
    }

    .company-select-id {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #000000;
      font: normal normal 400 14px/24px Montserrat;
      margin-right: 1.6rem;
    }

    .searchIcon {
      width: 45px;
      background-color: transparent;
      display: grid;
      place-items: center;

      .searchIcon svg {
        border: 1px solid black;
        font-size: 20px;
      }

      #searchBtn {
        cursor: pointer;
        font-size: 1rem;
      }

      #clearBtn {
        cursor: pointer;
        font-size: 1rem;
      }
    }
  }
  .change-border {
    border: 1px solid #0092ff;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom: none;
    padding: 6px 0;
  }

  .dataResult {
    position: absolute;
    z-index: 100;
    top: 80px;
    right: 0px;
    border: 1px solid #0092ff;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top: 0;
    width: calc(37vw);
    overflow-y: scroll;
    cursor: pointer;
    height: auto;
    max-height: 100px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 8px;
      border-bottom-right-radius: 4rem;
      margin-bottom: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cbcbcb;
      border-radius: 8px;
    }

    .dataItem {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: #fff;
      overflow: hidden;

      &:hover {
        background-color: #f5f5f5;
      }

      p.company-name {
        padding: 1rem;
        font-size: 14px;
        color: #333;
        cursor: pointer;
      }
      p.company-id {
        font-size: 14px;
        color: #333;
        cursor: pointer;
        margin-left: auto;
        margin-right: 4rem;
      }
    }
  }
}
