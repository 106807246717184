// Variables
$border-color: #ccc;
$primary-text: #43425d;
$hover-bg: #f5f5f5;
$tooltip-border: #0092ff;

.settlement-view {
  padding: 20px;

  &__loading,
  &__error {
    padding: 1rem;
    text-align: center;
    font-size: 1rem;
    color: $primary-text;
  }

  .ui-form-details {
    margin-bottom: 2rem;
  }

  .response-details-container {
    border: 1px solid $border-color;
    margin-top: 1.25rem;
    padding: 1rem;
    border-radius: 8px;
    background: #fff;

    .accordion-header {
      cursor: pointer;
      padding: 0.5rem;
      transition: background-color 0.2s;
      F &:hover {
        background-color: $hover-bg;
        border-radius: 4px;
      }

      h4 {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        color: $primary-text;

        p {
          font-size: 0.875rem;
          font-weight: 500;
          margin: 0;
          display: flex;
          align-items: center;
        }

        .accordion-icon {
          font-size: 0.75rem;
          color: $primary-text;
        }
      }
    }

    .response-details-divider {
      height: 1px;
      background-color: $border-color;
      margin: 1rem 0;
    }

    .response-details {
      .response-list {
        list-style: none;
        padding: 0;
        margin: 0;

        .response-list-item {
          display: flex;
          padding: 0.75rem 0;
          border-bottom: 1px solid rgba($border-color, 0.3);
          align-items: flex-start;

          &:last-child {
            border-bottom: none;
          }

          .field {
            flex: 0 0 250px;
            font-weight: 500;
            color: $primary-text;
            font-size: 0.875rem;
            padding-right: 1rem;
          }

          .value,
          .consumers-value {
            flex: 1;
            text-align: right;
            word-break: break-word;
            font-size: 0.875rem;
            color: $primary-text;
            max-width: calc(100% - 250px);
          }

          .consumers-value {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 0.5rem;

            > span {
              background: #f5f5f5;
              padding: 0.25rem 0.5rem;
              border-radius: 4px;
              display: inline-block;
            }
          }

          .settlement-urn__details {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            .consumers-urn__value {
              background: #f5f5f5;
              padding: 0.25rem 0.5rem;
              border-radius: 4px;
              .consumer-value__label {
                font-size: 0.8rem;
              }
              .consumer-value {
                font-size: 0.725rem;
              }
            }
          }

          .view-all__action {
            font-size: 12px;
            font-weight: 600;
            padding: .2rem 0.6rem;
            color: #0092ff;
            border-radius: 1rem;
            height: max-content;
            margin-top: auto;
          }

          ul {
            list-style: none;
            padding-left: 1rem;
            margin: 0.5rem 0;
            width: 100%;
            text-align: right;

            li {
              margin: 0.25rem 0;
            }
          }
        }
      }
    }
  }

  // Tooltip styles
  .tooltip-container {
    position: relative;
    display: inline-block;
    margin-left: 0.5rem;

    .info-icon {
      color: $tooltip-border;
      cursor: default;
      font-size: 0.875rem;
    }

    .tooltip-text {
      visibility: hidden;
      width: 250px;
      background-color: #fff;
      border: 1px solid $tooltip-border;
      border-radius: 6px;
      padding: 0.75rem;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.75rem;
      line-height: 1.4;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border: 5px solid transparent;
        border-top-color: $tooltip-border;
      }
    }

    &:hover .tooltip-text {
      visibility: visible;
    }
  }
}
