.virtual-accounts-page {
  // height: 100vh;

  .virtual-accounts-content {
    padding: 18px 0px 80px 0px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    height: max-content;

    .virtual-accounts {
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1.heading {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: #43425d;
        display: flex;
        align-items: center;
      }
    }

    .virtual-accounts-card {
      margin-top: 20px;
    }
  }
}

// BTN CSS
.configure-callback-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  border: none;
  outline: none;
  padding: 0.725rem 28px;
  border-radius: 100px;
  background: #0092ff;
  color: #ffffff;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  span {
    font-size: 1.4rem;
  }
}
