.customer-configuration-page {
  // height: 100vh;

  .customer-configuration-content {
    padding: 18px 0px 80px 0px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    height: max-content;

    .customer-configuration {
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1.heading {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: #43425d;
        display: flex;
        align-items: center;
      }
    }

    .customer-configuration-card {
      margin-top: 20px;
    }
  }
}

// BTN CSS
.fetch-va-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  border: none;
  outline: none;
  padding: 10px 28px;
  border-radius: 18px;
  background: #0092ff;
  color: #ffffff;
  opacity: 1;
  font-size: 13px;
  font-weight: 500;
}
