.google-login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background: url("../../assets/login-bg.svg") no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #000;

  .google-form-section {
    width: 36vw;
    height: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;

    .google-input-section {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 4rem 2rem;
      width: 100%;
      background: #fff;
      border: 1px solid rgb(237, 242, 245);
      border-radius: 10px;
      box-shadow: rgb(0 0 0 / 8%) 0px 7px 20px;
      .decentro-logo {
        width: 14vw;
        margin-bottom: 2rem;
      }

      h1 {
        font-size: 1rem;
        color: #4d4f5c;
        font-weight: 500;
        margin-top: 40px;
        text-align: center;
      }

      button.google-login-button {
        display: flex;
        margin: 45px auto 0px auto;
        background: #fff;
        padding: 1.2rem 2rem;
        color: #4c5773;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 8px;
        border: 1px solid #f0f0f0;
        box-shadow: 0 2px 2px rgb(0 0 0 / 13%);
        display: flex;
        align-items: center;
        justify-content: center;
        .google-login-icon {
          width: 1.2rem;
          margin-right: 0.2rem;
        }
      }

      .alert {
        font-size: 0.9rem;
        margin-top: 3rem;
        padding: 1.2rem;
        background-color: #ed6e6e;
        border-radius: 8px;
        color: white;
        opacity: 0.8;
        transition: opacity 0.6s;
        text-align: center;
      }
    }
  }
}
