// Toggle Switch
.toggle-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0rem;
  .toggle-input-label {
    color: #43425d;
    font-size: 0.9rem;
    font-weight: 600;
    // margin-right: 1rem;
    text-align: left;
  }

  .toggle-switch {
    position: relative;
    width: 3.5rem;
    height: 1.375rem;
    margin-left: 1.2rem;

    .toggle-input {
      display: none; // Hide the default checkbox
    }

    .toggle-label {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #ccc;
      border-radius: 30px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      position: relative;

      .toggle-thumb {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 50%;
        transition: left 0.3s ease;
      }
    }

    // When checked, change background and thumb position
    .toggle-input:checked + .toggle-label {
      background-color: #0092ff; // Background when ON
    }

    .toggle-input:checked + .toggle-label .toggle-thumb {
      left: 18px; // Move thumb to the right
    }
  }
}
