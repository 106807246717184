.tab-card-components {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    h1.heading {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      color: #43425d;
      display: flex;
      align-items: center;
    }
  }
  
  .tab-card-components-page {
    height: 100%;
    overflow-y: auto;
  
    .tab-card-components-content {
      // padding: 18px 30px 0px 30px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
      // height: 100%;
  
      .tab-card-components-forms {
        // margin-top: 20px;

        .MuiTabs-flexContainer{
          height: 40px;
        }
  
        .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: center;
        }
  
        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
          width: calc(100% / 5) !important;
        }
  
        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-disabled {
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: center;
          line-height: 16px;
          padding: 15px;
          transform: scale(1);
          background-color: #f7f7f7;
          color: #d1d1d1 !important;
          font-weight: 600 !important;
          font-family: Montserrat;
        }
  
        .tab-card-module {
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          line-height: 16px;
          padding: 15px;
          transform: scale(1);
          background-color: #f7f7f7;
          color: #000000;
          font-weight: 600;
          font-family: Montserrat;
          // width: 250px;
          width: calc(100% / 4);
          max-width: -webkit-fill-available;
        }
  
        .tab-card-module.Mui-selected {
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border: 1px solid #e3f1fb;
          background-color: #e3f1fb;
          transform: scale(1);
          color: #0092ff;
          font-size: 10px;
          line-height: 16px;
          padding: 20px 15px;
          font-weight: 600;
          font-family: Montserrat;
        }

  
        .MuiTabs-indicator {
          -webkit-transition: all 0s !important;
          transition: all 0s !important;
          background-color: #0092ff;
          width: 25% !important;
        }
  
        .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
          display: none;
        }
      }
  
    }
  }
  