.add-callback-page {
  height: 81vh;
  overflow-y: auto;

  .add-callback-content {
    padding: 18px 0px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);

    .add-callback-card {
      border-radius: 10px;
      margin: 2rem;
      margin-top: 1rem;
      min-height: 100vh;
      height: 100%;
    }
  }
}
