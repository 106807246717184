.ui-channel-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 0.8rem;

  button {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #f5f5f5;
    border-radius: 100px;
    background-color: #f5f5f5;
    color: #d1d1d1;
    padding: 12px 24px;
    font-size: 14px;
    line-height: 17px;
    gap: 0.4rem;

    &.active {
      background-color: #0092ff;
      color: #ffffff;
    }

    &.secondary-btn {
      border: 1px solid #0092ff;
      background-color: #ffffff;
      color: #0092ff;
    }

    span {
      margin-left: 5px;
    }
  }

  &.add-more {
    justify-content: center;
  }
}

.css-1ieujw7-control {
  width: 100% !important;
}

.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .error-message {
    color: red;
    font-size: 0.925rem;
    font-weight: 600;
  }
}
