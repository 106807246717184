.app-main{
	display: flex;
	flex-flow: column;
	width: 100%;
	height: 100%;
	.app-content{
		width: 100%;
		height: 100%;
		display: flex;
	}
}