.added-callbacks {
  .edit-list-heading {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 2rem 0;
    color: #43425d;
    // border-top: 1px solid #cbcbcb;
    // border-bottom: 1px solid #cbcbcb;
  }

  .callback-list__container {
    border-bottom: 1px solid #cbcbcb;
    padding: 2rem 0px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    .field-details {
      flex: 1;
      display: flex;
      gap: 2.6rem;
      .list-row__left {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .response {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .response-label {
            font-weight: 600;
            color: #43425d;
            font-size: 1rem;
          }
          .response-value {
            font-size: 0.9rem;
            .copy-header-icon {
              padding-left: 8px;
              width: 1.4rem;
              cursor: pointer;
            }
          }
        }
      }
      .list-row__right {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .response {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .response-label {
            font-weight: 600;
            color: #43425d;
            font-size: 1rem;
          }
          .response-value {
            font-size: 0.9rem;
          }
        }
        .url-callback-value {
          width: 24vw;
          overflow-wrap: break-word;
        }
      }
    }
    .edit-btn-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 0.5rem 2rem;
      padding-right: 0;
      .callback-edit-btn {
        padding: 10px 52px;
        background-color: transparent;
        color: #0092ff;
        border-radius: 18px;
        border: 2px solid #0092ff;
        font-size: 13px;
        font-weight: 500;
      }
    }
    .delete-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 0;
      padding-top: 1.8rem;
      .callback-delete-btn {
        padding: 10px 42px;
        background-color: transparent;
        color: #0092ff;
        border-radius: 18px;
        border: 2px solid #0092ff;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

// callback loader
.callback-loader-container {
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 3rem;
  }
}

.ml-4 {
  margin-left: 0.4rem;
}

.pointer-cursor {
  cursor: pointer;
}
