.change-log-contents {
  //   padding: 30px;
  padding: 18px 30px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);

  h2 {
    margin-bottom: 40px;
  }

  h3 {
    font-size: 24px;
    color: #000000;
  }

  h4 {
    font-size: 20px;
    line-height: 24px;
  }

  ul {
    list-style: initial;

    li {
      margin-left: 50px;
      margin-top: 9px;
    }

    li:last-child {
      margin-bottom: 30px;
    }
  }
}
