.submenu-wrapper {
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 20px 20px 20px 20px;
  position: relative;
  transition: 0.3s all;
  .submenu-bar {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    // width: 300px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #fafafa;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0000000a;
    background-color: #ffffff;
    padding-top: 10px;

    ul.nav-primary {
      overflow-x: hidden;

      li {
        display: flex;
        flex-flow: column;
        a {
          position: relative;
          display: flex;
          flex-flow: row;
          align-items: center;
          cursor: pointer;
          padding: 16px 20px;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: #43425d;
          display: flex;
          align-items: center;
          width: max-content;

          img {
            filter: grayscale(100%);
          }

          img.module-icon {
            margin-right: 20px;
            height: 20px;
            width: 20px;
          }

          &.active {
            border-color: rgba(0, 146, 255, 1);
            color: rgba(0, 146, 255, 1);

            img {
              filter: unset;
            }
          }
        }
      }
    }
  }

  &.collapsed-submenu {
    width: 100px;
  }

  &.extended-submenu {
    // width: 340px;
    width: 370px;
    overflow-x: hidden;
    // z-index: 100;
    z-index: 0;
  }
}

@media screen and (max-width: 1280px) {
  .submenu-wrapper {
    .submenu-bar {
      ul.nav-primary {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .submenu-wrapper {
    .submenu-bar {
      ul.nav-primary {
        li {
          a {
            // font-size: 14px;
          }
        }
      }
    }

    &.extended-submenu {
      // width: 270px;
      width: 370px;
    }
  }
}
