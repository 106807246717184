.ui-success__wrapper {
  padding: 0px;
  margin-left: auto;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: revealanimation;
  animation-duration: 1s;
  .success-status-icon {
    padding: 0px;
    margin: 0px;
    width: 2.4rem;
    height: 2.4rem;
  }
}
@keyframes revealanimation {
  0% {
    opacity: 0;
    max-height: 15px;
  }
  100% {
    opacity: 1;
    max-height: 350px;
  }
}
