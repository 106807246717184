#portal .container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}

.container .modal {
  z-index: 2;
  width: max-content;
  height: max-content;
  padding: 1rem 1.5rem;
  background: white;
  border-radius: 10px;
  position: relative;
  animation: animate 0.3s;
  min-width: 600px;
}
.close-modal-btn {
  position: absolute;
  top: -28px;
  right: -28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f9ff 0% 0% no-repeat padding-box;
  color: white;
  padding: 4px;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 50%;

  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    color: #0092ff;
  }
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

// modal heading styles
.edit-callback-heading {
  margin-top: 10px;
  margin-bottom: 5px;
  p {
    font-size: 22px;
    font-weight: 600;
    color: #43425d;
  }
}

.edit-callback-subtitle {
  text-align: left;
  font: normal normal normal 13px/16px Montserrat;
  font-weight: 500;
  letter-spacing: 0px;
  color: #808495;
}

// input fields
.edit-callback-fields {
  margin-top: 1rem;
  width: 32rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // common(default) input callback field .
  .text-input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text-input-label {
      font-size: 1rem;
      font-weight: 500;
      color: #43425d;
      user-select: none;
      position: relative;
      &::after {
        content: "*";
        color: red;
        position: absolute;
        top: 0;
        right: -8px;
      }
    }
    // w/o required sign
    .text-input-label-without-required {
      font-size: 1rem;
      font-weight: 500;
      color: #43425d;
      user-select: none;
      position: relative;
    }
  }
  .add-callback-input {
    width: 80%;
    border-radius: 10px;
    border: 1px solid #cbcbcb;
    .callback-field-input {
      background-color: transparent;
      outline: none;
      border: 0;
      font-size: 14px;
      padding: 8px 18px;
      width: 100%;
      font: normal normal 400 14px/24px Montserrat;
      user-select: none;
      color: #000000;

      &::placeholder {
        font-weight: normal;
        color: #9f9f9f;
      }

      &:focus {
        outline: none;
      }
      &.callback-default-filled {
        user-select: none;
        cursor: not-allowed;
      }
    }
  }
  .add-callback-input.callback-read-only {
    background-color: #f1f1f1;
  }
  .error-border {
    border: 1px solid red;
  }
}

// http dropdown
.dropdown-input-wrapper {
  display: flex;
  align-items: center;
  gap: 4rem;
  .http-input-label {
    font-size: 1rem;
    font-weight: 500;
    color: #43425d;
    user-select: none;
    position: relative;
    &::after {
      content: "*";
      color: red;
      position: absolute;
      top: 0;
      right: -8px;
    }
  }
}

// update Btn
.edit-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  .update-callback-details {
    border: none;
    outline: none;
    padding: 10px 30px;
    border-radius: 18px;
    background: #0092ff;
    color: #ffffff;
    opacity: 0.5;
    font-size: 13px;
    font-weight: 500;
  }
  .active-update-btn {
    opacity: 1;
  }
}
