.ui-expendable-button {
  background-color: transparent;
  gap: 8px;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  width: 2px;
  margin-right: 10px;
}

.ui-accordion-container {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-top: 10px;
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  padding: 1rem;
}

.ui-underline {
  width: 100%;
  height: 0.10rem;
  background: #cbcbcb;
  margin: 20px auto;
}
.ui-column-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  .ui-column-text {
    font-size: 1rem;
    font-weight: 500;
  }
}

.ui-text-container {
  text-align: left;
  max-height: 350px;
  opacity: 1;
  animation-name: revealAnimation;
  animation-duration: 1s;
}

@keyframes revealAnimation {
  0% {
    opacity: 0;
    max-height: 15px;
  }
  100% {
    opacity: 1;
    max-height: 350px;
  }
}
