.module-provider-prod-form {
  .form-heading {
    font-size: 0.9rem;
    font-weight: 600;
    color: #9f9f9f;
    text-align: left;
  }

  .module-selection {
    // max-width: 62rem;
  }

  .module-selection-container {
    display: flex;
    justify-content: flex-start;
    gap: 5rem;
    .select-modules-heading {
      font-size: 0.9rem;
      font-weight: 600;
      color: #43425d;
      margin-top: 1.5rem;
      white-space: nowrap;
    }
    .select-modules-container {
      // display: flex;
      // flex-direction: column;
      // gap: 1rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      .module-select-container {
        display: flex;
        background-color: #fafafa;
        padding: 0.8rem 1rem;
        width: max-content;
        border-radius: 10px;
        .module-checkbox-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;
          width: 15rem;
        }
        .module-heading {
          font-size: 0.9rem;
        }

        .vertical-divider {
          border-right: 1px solid #e6e6e6;
          margin-inline: 2rem;
        }

        .provider-dropdown-container {
          max-width: 20rem;
          align-self: flex-end;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          .provider-heading {
            color: #43425d;
            font-size: 0.9rem;
            font-weight: 600;
            white-space: nowrap;
          }
          // css-1dimb5e-singleValue
          .css-13cymwt-control {
            width: 15.1rem;
          }
          .css-3iigni-container {
            width: 16rem;
          }
          .css-1fdsijx-ValueContainer {
            font-size: 0.7rem !important;
          }
          .css-1nmdiq5-menu {
            font-size: 0.7rem !important;
          }
          .css-1dimb5e-singleValue {
            font-size: 0.7rem !important;
          }
          .css-16xfy0z-control {
            font-size: 0.8rem !important;
          }
          .css-1jqq78o-placeholder {
            font-size: 0.8rem !important;
          }

          .css-t3ipsp-control {
            width: 15.1rem;
            color: #0092ff;
          }
        }
      }
    }
  }

  .mva-creation-container {
    margin-top: 2rem;
    margin-left: 12rem;
    .mva-heading-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
      .mva-heading {
        color: #43425d;
        font-size: 0.9rem;
        font-weight: 600;
        white-space: nowrap;
      }
    }

    .mva-select-container {
      display: flex;
      background-color: #fafafa;
      padding: 0.8rem 1rem;
      width: max-content;
      border-radius: 10px;
      .module-checkbox-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        width: 15rem;
      }
      .module-heading {
        font-size: 0.9rem;
      }
      .mva-provider-dropdown-container {
        max-width: 20rem;
        align-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .mva-provider-heading {
          color: #43425d;
          font-size: 0.9rem;
          font-weight: 600;
          white-space: nowrap;
        }
        .css-13cymwt-control {
          width: 15.1rem;
        }
        .css-3iigni-container {
          width: 16rem;
        }
        .css-1fdsijx-ValueContainer {
          font-size: 0.7rem !important;
        }
        .css-1nmdiq5-menu {
          font-size: 0.7rem !important;
        }
        .css-1dimb5e-singleValue {
          font-size: 0.7rem !important;
        }
        .css-16xfy0z-control {
          font-size: 0.8rem !important;
        }
        .css-1jqq78o-placeholder {
          font-size: 0.8rem !important;
        }
        .css-t3ipsp-control {
          width: 15.1rem;
          color: #0092ff;
        }
      }
    }
  }

  .module-input-container {
    display: flex;
    justify-content: flex-start;
    gap: 5rem;

    .text-field-wrapper {
      display: flex;
      gap: 4rem;
      align-items: center;
      margin-top: 1.5rem;

      .error-prepaid-message {
        font-size: 14px;
        color: #ff0000;
      }
    }

    .module-textfield-label {
      font-size: 0.9rem;
      font-weight: 600;
      color: #43425d;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      white-space: nowrap;
    }

    .prepaid-field-container {
      width: 22rem;
      .module-textfield {
        width: 100%;
        border: 1px solid #cbcbcb;
        border-radius: 10px;
        padding: 14px;
      }
    }
  }

  .module-provider-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 4rem;
    .formik-btn-submit {
      margin-left: auto;
      border: 1px solid #f5f5f5;
      border-radius: 50px;
      padding: 12px 24px;
      background-color: #0092ff;
      color: #ffffff;
      font-size: 14px;
    }

    .formik-btn-submit.active {
      color: #ffffff;
      background-color: #0092ff; /* Change background color for active state */
    }

    .formik-btn-submit.disabled {
      opacity: 0.5; /* Reduce opacity when button is disabled */
      cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
    }
  }
}
