.verified-settlement-container {
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  .verified-success-account-details {
    border: 1px solid #0092ff;
    padding: 1rem;
    border-radius: 10px;

    table {
      display: flex;
      align-items: center;
      justify-content: center;

      td {
        font-size: 12px;
        font-weight: 500;
        color: #43425d;
        padding: 12px;
        text-align: left;
      }

      .row-account__details {
        border-bottom: none !important ;
      }

      .detail-heading {
        font-size: 0.7rem;
        font-weight: 600;
        color: #43425d;
      }

      .detail-value {
        font-size: 0.7rem;
        font-weight: 500;
        color: #000000;
      }

      .copy-link-icon {
        cursor: pointer;
        width: 14px;
        margin-left: 8px;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
