.login {
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row;

	.login-section {
		width: 50%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;

		.form-section {
			width: 65%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-flow: column;
			justify-content: center;

			h1 {
				font-size: 17px;
				color: #4D4F5C;
				font-weight: 400;
				margin-top: 40px;
			}

			.input-section {
				display: flex;
				flex-flow: column;
				align-items: center;
				margin: 15px 0 0 0;
				width: 100%;

				form {
					width: 100%;

					.inputs {
						display: flex;
						flex-flow: column;
						width: 100%;

						input {
							width: 100%;
							margin-top: 45px;
						}
					}

					.login-options {
						margin-top: 45px;
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						a {
							color: #0092FF;
							font-size: 13px;
							background: transparent;
						}

						.remember-me {
							display: flex;
							align-items: center;

							input {
								margin: 0;
								border-radius: 2px;
								border: 1px solid #808495;
							}

							label {
								font-size: 13px;
								color: #43425D;
								margin-left: 10px;
							}
						}
					}

					button.login-button {
						display: flex;
						margin: 45px auto 0px auto;
						border-radius: 25px;
						background: #0092FF;
						padding: 10px 40px;
						color: #FFFFFF;
						font-size: 16px;
						font-weight: 400;
						border: 1px solid transparent !important;
					}

					span.error {
						color: #ff0000;
						font-size: 10px;
						font-weight: 500;
						margin-top: 4px;
					}
				}

				.signup-message {
					margin-top: 30px;

					p {
						font-size: 13px;
						color: #43425D;

						a {
							color: #0092FF;
							font-size: 13px;
							background: transparent;
						}
					}
				}
			}

			.otp-section {
				align-items: flex-start;

				.back-button {
					margin-top: 40px;
					display: flex;
					align-items: center;

					img {
						width: 16px;
						cursor: pointer;
					}

					label {
						font-size: 17px;
						color: #43425D;
						margin-left: 10px;
					}
				}

				.login-buttons {
					width: 100%;
					align-items: center;
				}
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.login {
		.login-section {
			margin: auto;
			width: 65%;
		}
	}
}

@media screen and (max-width: 710px) {
	.login {
		.login-section {
			margin: auto;
			width: 100%;

			.form-section {
				img {
					width: 200px;
				}
			}
		}
	}
}