.ui-form-edit-field-container {
  //   border: 1px solid red;
  width: calc(100% / 2 - 50px);

  .ui-form-edit-field {
    display: flex;
    align-items: center; /* Center content vertically */
    // gap: 1rem;
  }

  .ui-form-edit__label {
    // border: 1px solid blue;
    width: 50%;
    display: block;
    word-break: break-word; /* Ensures long words break */
    white-space: normal; /* Allows wrapping */
    hyphens: auto; /* Automatically adds hyphen when breaking words */
    text-align: left;
    color: #43425d;
    font-size: 14px;
    font-weight: 600;
    margin-right: 1.2rem;
  }

  .ui-form-edit-field__prefill-value {
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    height: 3.125rem;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
  }

  .ui-form-edit-field__input {
    // border: 1px solid red;
    // border-radius: 10px;
    // padding: 14px;
    width: 94%;
    margin-left: auto;

    .ui-form-input-box {
      border: 1px solid #cbcbcb;
      border-radius: 10px;
      padding: 14px;
      width: 100%;
    }

    .ui-form-input-box.input-error {
      border-color: #de6767;
      border-width: 1.5px;
    }
  }

  .field__actions {
    width: 1rem;
    // border: 1px solid orange;
    padding: 0rem 0.2rem;
    .action-btn {
      background-color: transparent;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .edit-field {
      svg {
        width: 0.825rem;
        height: 0.825rem;
        color: #0092ff;
      }
    }
    .save-field {
      svg {
        color: green;
      }
    }
    .cancel-field {
      svg {
        color: red;
      }
    }
  }
}
