.shared-tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.shared-tooltip-tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-40%);
  padding: 6px;
  font-size: 12px;
  box-shadow: 0px 2px 10px #00000027;
  background-color: #ffffff;
  color: #4d565c;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1;
  z-index: 100;
  white-space: no-wrap;
  line-height: 1.4;
}

/* css border triangles */
.shared-tooltip-tip::before {
  content: " ";
  left: 40%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
  margin-left: calc(6px * -1);
}

.shared-tooltip-tip.top {
  top: calc(32px * -1);
  left: 80px;
}
/* css border triangles */
.shared-tooltip-tip.top::before {
  top: 100%;
  border-top-color: #9f9f9f;
}

.shared-tooltip-tip.right {
  left: calc(100% + 52px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* css border triangles */
.shared-tooltip-tip.right::before {
  left: calc(6px * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: #9f9f9f;
}

.shared-tooltip-tip.bottom {
  bottom: calc(52px * -1);
}
/* css border triangles */
.shared-tooltip-tip.bottom::before {
  bottom: 100%;
  border-bottom-color: #9f9f9f;
}

.shared-tooltip-tip.left {
  left: auto;
  right: calc(100% + 52px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* css border triangles */
.shared-tooltip-tip.left::before {
  left: auto;
  right: calc(6px * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: #9f9f9f;
}
