.checkbox-container {
  display: flex;
  gap: 10px;
  input {
    accent-color: #3b82f6;
  }
  label {
    padding: 0px !important;
  }
}
