// Dropdown CSS
.dropdown-http-type {
  width: 100%;
  pointer-events: none;
  cursor: not-allowed;
  user-select: none;
  position: relative;
  display: block;
  z-index: 0;
  .dropdown-divider {
    background-color: #fff;
    span {
      display: block;
      height: 1px;
      background-color: transparent;
      width: 86%;
      margin: 0 auto;
    }
  }
  .text-dark {
    color: #333;
  }

  .text-light {
    color: #9f9f9f;
  }

  .dropdown-http-label {
    font-size: 1rem;
    font-weight: 500;
    color: #43425d;
    user-select: none;
    margin-bottom: 8px;
    &::after {
      content: " *";
      color: red;
    }
  }

  .dropdown-btn {
    padding: 8px 18px;
    border-radius: 10px;
    background: #fff;
    font-size: 12px;
    font-weight: 400;
    font: normal normal 400 14px/24px Montserrat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cbcbcb;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #43425d;
    }
  }
  .dropdown-btn.border-radius__invert {
    border: 1px solid #0092ff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }

  .http-type-content {
    position: absolute;
    z-index: 99;
    top: 68px;
    left: 0;
    padding: 0 10px;
    background: #fff;
    border: 1px solid #0092ff;
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 2px 6px #0000000a;
    font-weight: 400;
    color: #333;
    width: 100%;
    max-height: 150px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 8px;
      margin-bottom: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      // background: #000;
      background: #cbcbcb;
      border-radius: 8px;
    }
  }

  .http-type-content {
    .dropdown-item {
      font-size: 12px;
      padding: 16px 10px;
      cursor: pointer;
      transition: all 0.2s;
    }
    .dropdown-item:hover {
      background: #f4f4f4;
    }
  }
}

// dropdown arrow
.dropdown-arrow {
  width: 18px;
}
.dropdown-arrow__rotate {
  transition: transform 0.5s ease-in-out;
  transform: rotate(180deg);
}

// loader skeleton
.httptype-loader-skeleton {
  margin-top: 0.4rem;
  width: 50%;
  height: 44px;
  border-radius: 10px;

  .skeleton-loader {
    // width: 24rem;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: block;
    background: linear-gradient(
        to right,
        rgba(248, 242, 242, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      #d7d7d796;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
  }
  @keyframes shine {
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
  }
}
