.ui-copy-button {
  background: none;
  margin-left: 16px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;

  img {
    height: 14px;
  }
}

.mis-copybtn {
  background: none;
  margin-left: 12px;
  display: flex;
  align-items: flex-end;

  img {
    height: 14px;
  }
}
