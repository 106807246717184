.onboard-customer-prod-page {
  // height: 100vh;

  .onboard-customer-prod {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .onboard-customer-prod-content {
    padding: 18px 30px 0px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    height: max-content;

    .onboard-customer-forms {
      margin-top: 20px;

      .MuiTabPanel-root {
        padding: 24px;
        overflow-y: scroll;
        height: calc(100vh - 285px);
      }

      .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
      }

      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        width: calc(100% / 5) !important;
      }

      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-disabled {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 16px;
        padding: 15px;
        transform: scale(1);
        background-color: #f7f7f7;
        color: #d1d1d1 !important;
        font-weight: 600 !important;
        font-family: Montserrat;
      }

      .onboard-tab {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 16px;
        padding: 15px;
        transform: scale(1);
        background-color: #f7f7f7;
        color: #d1d1d1;
        font-weight: 600;
        font-family: Montserrat;
        // width: 250px;
        width: calc(100% / 4);
        max-width: -webkit-fill-available;
      }

      .onboard-tab.Mui-selected {
        display: flex;
        flex: 1;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid #e3f1fb;
        background-color: #e3f1fb;
        transform: scale(1);
        color: #0092ff;
        font-size: 14px;
        line-height: 16px;
        padding: 20px 15px;
        font-weight: 600;
        font-family: Montserrat;
      }

      .onboard-tab.Mui-disabled {
        display: flex;
        flex: 1;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 16px;
        padding: 20px 15px;
        transform: scale(1);
        font-weight: 600;
        color: #000000;
        background-color: #f7f7f7;
        font-family: Montserrat;
      }

      .MuiTabs-indicator {
        -webkit-transition: all 0s !important;
        transition: all 0s !important;
        background-color: #0092ff;
        // width: 33.5% !important;
        // width: 25.1% !important;
      }

      .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
        display: none;
      }
    }

    .form-title {
      color: #43425d;
      font-size: 14px;
      text-align: left;
      line-height: 18px;
      font-weight: 600;
    }

    .form-content {
      display: flex;
      flex-flow: column;

      .form-inputs-section {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 40px;

        .form-content-input {
          display: flex;
          flex-flow: row-reverse;
          align-items: center;
          width: calc(100% / 2 - 50px);
          justify-content: flex-end;

          .label {
            color: #43425d;
            font-size: 0.825rem;
            text-align: left;
            line-height: 18px;
            font-weight: 600;
            width: 30%;
          }

          .form-input-box {
            width: 70%;
            border: 1px solid #cbcbcb;
            border-radius: 10px;
            padding: 14px;
          }
        }
      }
    }

    .button-container {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-end;
      margin: 3rem 0 2rem 0;

      button {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #f5f5f5;
        border-radius: 100px;
        padding: 15px 30px;
        background-color: #f5f5f5;
        color: #d1d1d1;
        font-size: 17px;
        line-height: 17px;

        &.active {
          background-color: #0092ff;
          color: #ffffff;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }
}
