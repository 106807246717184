.response-details-container {
  border: 1px solid #ccc;
  margin-top: 2rem;
  padding: 20px;
  width: 100%;
  border-radius: 10px;

  .response-details-heading {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 30px;
    color: #43425d;

    .failure-status-icon {
      background: #fdf3f3;
      height: 1.125rem;
      margin-right: 5px;
      border-radius: 8px;
    }

    .success-status-icon {
      background: #f1fcf6;
      height: 1.125rem;
      margin-right: 5px;
      border-radius: 8px;
    }

    .close-response-details {
      margin-left: auto;
      height: 1.125rem;
      cursor: pointer;
    }
    .right-icons-container {
      display: flex;
      gap: 10px;
      margin-left: auto;
      align-items: center;
    }
  }

  .response-details-divider {
    border-top: 1px solid #cbcbcb;
    margin: 1rem 0;
  }
}

.response-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  .field {
    color: #43425d;
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    margin-right: 0.5rem;
  }

  .value {
    color: #43425d;
    font-size: 12px;
  }
}
