.failure-dialog {

  .MuiDialog-paper {
    position: relative;
    overflow: visible !important;
  }

  div.close-failure-popup {
    right: -28px;
    top: -28px;
    position: absolute;
    z-index: 100;
    background: #f2f9ff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #0092ff;
    }
  }

  &-heading {
    margin-top: 10px;
    p {
      font-size: 22px;
      font-weight: 600;
      color: #43425d;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 475px;
    height: 250px;

    .failure-message {
      background: #fdf3f3;
      border-radius: 5px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 14px;
      padding: 10px 15px;
      padding-top: 0;
      height: 200px;
      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #4d4f5c;
      }
      p {
        color: #808495;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
