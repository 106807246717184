#portal .all-callbacks-container {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.all-callbacks-container {
  .modal {
    z-index: 2;
    width: max-content;
    height: max-content;
    padding: 1rem 1.5rem;
    background: white;
    border-radius: 10px;
    position: relative;
    animation: animate 0.3s;
    text-align: center;
  }

  .close-modal-btn {
    position: absolute;
    top: -28px;
    right: -28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f9ff 0% 0% no-repeat padding-box;
    color: white;
    padding: 4px;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 50%;

    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
      color: #0092ff;
    }
  }

  @keyframes animate {
    from {
      transform: scale(0.5);
    }

    to {
      transform: scale(1);
    }
  }

  .totp-heading {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .totp-heading p {
    color: #43425d;
    font-size: 22px;
    font-weight: 600;
  }

  .sub-heading-totp {
    color: #808495;
    font: normal normal normal 13px/16px Montserrat;
    font-weight: 500;
    letter-spacing: 0;
  }

  .edit-credit-fields {
    margin: 2rem 0;
    width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    // common(default) input callback field .
    .text-input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text-input-label {
        font-size: 1rem;
        font-weight: 500;
        color: #43425d;
        user-select: none;
        position: relative;

        &::after {
          content: "*";
          color: red;
          position: absolute;
          top: 0;
          right: -10px;
        }
      }
    }
    .error-border {
      border: 1px solid red;
    }
  }

  .add-totp-input {
    width: 60%;
    border-radius: 10px;
    border: 1px solid #cbcbcb;

    .totp-field-input {
      background-color: transparent;
      outline: none;
      border: 0;
      font-size: 14px;
      padding: 8px 18px;
      width: 100%;
      font: normal normal 400 14px/24px Montserrat;
      user-select: none;
      color: #000000;

      &::placeholder {
        font-weight: normal;
        color: #9f9f9f;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .error-response-block {
    width: 100%;
    background: #fdf3f3;
    color: #e06060;
    text-align: center;
    border-radius: 0.4rem;
    padding: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .error-response-block-permission {
    width: 100%;
    background: #fdf3f3;
    text-align: center;
    border-radius: 0.4rem;
    padding: 1rem;
    text-align: center;
    word-wrap: break-word; /* Ensure words break if they are too long */
    word-break: break-word; /* Alternative for better compatibility */
    overflow-wrap: break-word; /* Standard property for breaking words */
    p {
      width: 30rem;
      text-align: center;
      color: #e06060;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  .totp-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;

    .update-credit-btn {
      cursor: not-allowed;
      border: none;
      outline: none;
      padding: 10px 30px;
      border-radius: 18px;
      background: #0092ff;
      color: #ffffff;
      opacity: 0.5;
      font-size: 13px;
      font-weight: 500;
    }

    .active-update-btn {
      cursor: pointer;
      opacity: 1;
    }
  }

  .qr-container {
    width: 150px;
    margin: 2rem auto;
    border: 1px solid #80808059;
  }

  .qr-OTP {
    width: 100%;
  }
}
