.ui-settlement-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;

  .fail-highlight {
    color: red;
  }

  button {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #f5f5f5;
    border-radius: 100px;
    padding: 15px 30px;
    background-color: #f5f5f5;
    color: #d1d1d1;
    font-size: 17px;
    line-height: 17px;

    &.active {
      background-color: #0092ff;
      color: #ffffff;
    }

    span {
      margin-left: 5px;
    }
  }
}

.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .error-message {
    color: red;
    font-size: 0.925rem;
    font-weight: 600;
  }
}
