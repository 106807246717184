.slider-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.card-detail-slider {
  position: relative;
  table {
    width: 30rem;
    border-spacing: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    background-color: #f7f7f7;
    td {
      font-size: 0.8rem;
      color: #43425d;
      padding: 0.6em 1em !important;
    }
  }

  .company-name-heading {
    text-align: center;
    p {
      font-size: 1rem;
      font-weight: 600;
      color: #0092ff;
      margin: 2rem 0;
      margin-bottom: 1.5rem;
    }
  }

  .detail-heading {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
    color: #43425d;
    white-space: nowrap;
  }

  .detail-value {
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    color: #000000;
    padding-left: 4rem;
  }

  .right-arrow-btn {
    background: none;
  }

  .left-arrow-btn {
    background: none;
  }

  .slider-count {
    margin: 0 0.6rem;
  }

  button {
    border: none;
    outline: none;
  }

  .va-detail-row {
    border: 1px solid red;
  }

  .copy-va-details {
    position: absolute;
    top: 62px;
    right: 16px;
    padding: 12px 24px;
    border-radius: 20px;
    background-color: #e3f1fb;
    color: #0092ff;
    font-size: 12px;
    font-weight: 600;

    img {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
}

.loader-container {
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

// done btn
.done-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  .done-va-details {
    border: none;
    outline: none;
    padding: 10px 30px;
    border-radius: 18px;
    background: #0092ff;
    color: #ffffff;
    opacity: 1;
    font-size: 13px;
    font-weight: 500;
  }
}
