.response-details-container {
  border: 1px solid #ccc;
  margin-top: 2rem;
  padding: 20px;
  width: 100%;
  border-radius: 10px;

  .right-icons-sub-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
  }

  .response-details-heading {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 30px;
    color: #43425d;

    .failure-status-icon {
      background: #fdf3f3;
      height: 1.125rem;
      margin-right: 5px;
      border-radius: 8px;
    }
    .failed-status-icon {
      background: #fdf3f3;
      height: 1.125rem;
      margin-right: 5px;
      border-radius: 8px;
    }
    .success-status-icon {
      background: #f1fcf6;
      height: 1.125rem;
      margin-right: 5px;
      border-radius: 8px;
    }

    .close-response-details {
      margin-left: auto;
      height: 1.125rem;
      cursor: pointer;
    }
    .right-icons-container {
      display: flex;
      gap: 10px;
      margin-left: auto;
      align-items: center;
    }
  }

  .response-details-divider {
    border-top: 1px solid #cbcbcb;
    margin: 1rem 0;
  }
}

.response-details {
  .response-list {
    .response-list-item {
      line-height: 2;
      display: flex;

      .field {
        color: #43425d;
        font-size: 14px;
        text-align: left;
        font-weight: 500;
        display: inline-block;
        text-wrap: nowrap;
        margin-right: 8px;
      }

      .value {
        color: #43425d;
        font-size: 14px;
        margin-left: auto;
        text-align: right;
        text-wrap: wrap;
        &.status-active {
          font-weight: 600;
          color: rgba(137, 205, 36, 0.769);
        }
        &.status-inactive {
          color: #f44336;
          font-weight: 600;
        }
      }

      .status-icon {
        background: #fdf3f3;
        height: 14px;
        margin-left: 16px;
        margin-top: 7px;
        border-radius: 8px;
      }
    }
  }
}
